import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Tag } from 'antd'
import { StandardAvatar } from '../../../../components'
import { setState } from '../../AssignmentReducer'

const renderStatue = (statusId) => {
    let statusName = null
    switch (statusId) {
        case 1:
            statusName = <Tag color='default'>Khởi tạo</Tag>
            break
        case 2:
            statusName = <Tag color='processing'>Bắt đầu làm</Tag>
            break
        case 3:
            statusName = <Tag color='warning'>Đang làm</Tag>
            break
        default: // 4
            statusName = <Tag color='success'>Hoàn thành</Tag>
            break
    }
    return statusName
}

export default function TableTask() {
    const { works, isLoadingWorks, selectedWorks } = useSelector((state) => state.assignment)
    const dispatch = useDispatch()

    const columns = [
        {
            title: 'Tên công việc',
            dataIndex: 'WorkName',
        },
        {
            title: 'Thành viên',
            render: (value, record) => {
                return record.User !== null ? (
                    <Fragment>
                        <StandardAvatar src={record?.User?.AvatarUrl ?? ''} />
                        <span className='ms-2'>{record?.User?.FullName ?? ''}</span>
                    </Fragment>
                ) : null
            },
            className: 'pt-0 pb-0',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'StatusId',
            render: (value, record) => renderStatue(value),
        },
        {
            title: 'Ngày tạo',
            render: (_, record) => `${record.CreatedDate} ${record.CreatedTime}`,
        },
    ]

    const selectedWorkIds = selectedWorks.map((item) => item.WorkId)

    return (
        <Table
            size='middle'
            rowKey='WorkId'
            loading={isLoadingWorks}
            dataSource={works}
            columns={columns}
            pagination={false}
            rowSelection={{
                type: 'checkbox',
                onChange: (_, selectedRows) => {
                    dispatch(
                        setState({
                            key: 'selectedWorks',
                            value: selectedRows,
                        })
                    )
                },
                selectedRowKeys: selectedWorkIds,
            }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        let list = [...selectedWorks]
                        if (list.findIndex((n) => n.WorkId === record.WorkId) > -1) {
                            list = list.filter((n) => n.WorkId !== record.WorkId)
                        } else {
                            list.push(record)
                        }
                        dispatch(
                            setState({
                                key: 'selectedWorks',
                                value: list,
                            })
                        )
                    },
                }
            }}
        />
    )
}
