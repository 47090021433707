import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    role: localStorage.getItem('login_info') !== null ? JSON.parse(localStorage.getItem('login_info')).Role : '',
    fullName: localStorage.getItem('login_info') !== null ? JSON.parse(localStorage.getItem('login_info')).FullName : '',
}

export const GlobalRecuder = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setRole: (state, action) => {
            state.role = action.payload
        },
        setFullName: (state, action) => {
            state.fullName = action.payload
        },
        resetState: (state, action) => {
            return { ...initialState }
        }
    }
})

export const {
    setRole,
    setFullName,
    resetState
} = GlobalRecuder.actions

export default GlobalRecuder.reducer