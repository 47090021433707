import { Card, Space } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWorks, resetState } from './AssignmentReducer'
import { Filter, ModalAssignmentAdd, ModalAssignmentEdit, TableTask, ModalAssignmentUser } from './components'

export default function AssignmentPage() {
    const { filters, pagination, selectedWorks } = useSelector((state) => state.assignment)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetState())
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchWorks(filters, pagination, selectedWorks))
        // eslint-disable-next-line
    }, [filters])

    return (
        <Fragment>
            <Space direction='vertical' className='w-100'>
                <Card size='small'>
                    <Filter />
                </Card>
                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <TableTask />
                </Card>
            </Space>
            <ModalAssignmentAdd />
            <ModalAssignmentEdit />
            <ModalAssignmentUser />
        </Fragment>
    )
}
