import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setFullName, setRole } from './../../reducers/GlobalRecuder'
import './style.css'

export default function LoginPage() {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [isBusy, setIsBusy] = useState(false)

    useEffect(() => {
        const loginInfo = localStorage.getItem('login_info')
        if (loginInfo !== null) {
            setIsBusy(true)
            const loginJson = JSON.parse(loginInfo)
            const refreshToken = loginJson.RefreshToken
            axios
                .post('/user/refresh-token', {
                    RefreshToken: refreshToken,
                })
                .then(({ data }) => {
                    if (data.IsSuccess) {
                        localStorage.removeItem('login_info')
                        localStorage.setItem('login_info', JSON.stringify(data.Result))
                        axios.defaults.headers.Authorization = 'Bearer ' + data.Result.AccessToken
                        dispatch(setRole(data.Result.Role))
                        dispatch(setFullName(data.Result.FullName))
                        navigate('/task')
                    } else {
                        localStorage.removeItem('login_info')
                    }
                })
                .finally(() => {
                    setIsBusy(false)
                })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className='login-form'>
            <div className='form'>
                <div className='logo'>
                    <img alt='logo' src='/assets/imgs/logo.svg' />
                    <span className='text-gradient'>TMS</span>
                </div>
                <Form
                    autoComplete='off'
                    onFinish={(formData) => {
                        setIsBusy(!0)
                        axios
                            .post('/user/login', formData)
                            .then(({ data }) => {
                                if (data.IsSuccess) {
                                    dispatch(setRole(data.Result.Role))
                                    dispatch(setFullName(data.Result.FullName))

                                    axios.defaults.headers.Authorization = 'Bearer ' + data.Result.AccessToken
                                    localStorage.setItem('login_info', JSON.stringify(data.Result))
                                    navigate('/task')
                                }
                            })
                            .finally(() => setIsBusy(!1))
                    }}
                >
                    <Form.Item
                        name='UserName'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập tài khoản',
                            },
                            {
                                min: 3,
                                // eslint-disable-next-line
                                message: 'Tối thiểu ${min} kí tự',
                            },
                            {
                                max: 70,
                                // eslint-disable-next-line
                                message: 'Tối đa ${max} kí tự',
                            },
                        ]}
                    >
                        <Input placeholder='Tài khoản' autoFocus readOnly={isBusy} />
                    </Form.Item>
                    <Form.Item
                        name='Password'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mật khẩu',
                            },
                            {
                                min: 6,
                                // eslint-disable-next-line
                                message: 'Tối thiểu ${min} kí tự',
                            },
                            {
                                max: 70,
                                // eslint-disable-next-line
                                message: 'Tối đa ${max} kí tự',
                            },
                        ]}
                    >
                        <Input type='password' placeholder='Mật khẩu' readOnly={isBusy} />
                    </Form.Item>
                    <Button type='primary' htmlType='submit' loading={isBusy}>
                        Đăng nhập
                    </Button>
                </Form>
            </div>
        </div>
    )
}
