import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Table, message } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { RowAction } from './../../../../components'
import axios from 'axios'
import { setState } from './../../ProjectReducer'
import { useSelector, useDispatch } from 'react-redux'

function TableResource(props) {
    const { projects } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const { resources } = props

    const column = [
        {
            title: 'Tên file',
            dataIndex: 'FileName',
        },
        {
            title: 'Kích thước',
            dataIndex: 'FileSize',
        },
        {
            title: 'Mở rộng',
            dataIndex: 'FileExtension',
        },
        {
            title: '',
            align: 'right',
            render: (_, record) => (
                <Popconfirm
                    placement='topRight'
                    title='Bạn có chắc chắn muốn xóa tài liệu khỏi dự án không?'
                    onConfirm={() => removeResource(record)}
                    okText='Đồng ý'
                    cancelText='Không'
                >
                    <RowAction type='danger' icon={<DeleteOutlined />} title='Xóa' />
                </Popconfirm>
            ),
        },
    ]

    const removeResource = (resource) => {
        axios.delete(`/project/resource/delete/${resource.ResourceId}`).then(({ data }) => {
            if (data.IsSuccess) {
                const newProjects = [...projects].map((item) => {
                    return {
                        ...item,
                        Resources: item.Resources.filter((n) => n.ResourceId !== resource.ResourceId),
                    }
                })
                dispatch(
                    setState({
                        key: 'projects',
                        value: newProjects,
                    })
                )
                message.success('Xóa thành viên khỏi dự án thành công')
            }
        })
    }

    return <Table size='small' rowKey='ResourceId' dataSource={resources} columns={column} pagination={false} />
}

TableResource.propTypes = {
    resources: PropTypes.array,
}

TableResource.defaultProps = {
    resources: [],
}

export default TableResource
