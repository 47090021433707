import { Avatar, Table, Tag } from 'antd'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardAvatar } from '../../../../components'
import { refreshProjects, setState } from '../../ProjectReducer'
import { TableProjectDetail } from './..'

export default function TableProject() {
    const { projects, selectedProject, isLoadingProjects, pagination } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const columns = [
        {
            title: 'Dự án',
            dataIndex: 'ProjectName',
            render: (value, record) => (
                <Fragment>
                    <StandardAvatar src={record.AvatarUrl} />
                    <span className='ms-2'>{value}</span>
                </Fragment>
            ),
            className: 'pt-0 pb-0',
        },
        {
            title: 'Ghi chú',
            dataIndex: 'Note',
        },
        {
            title: 'Tiến độ',
            align: 'right',
            render: (_, record) => {
                const percent = record.WorkCount === 0 ? (0).toFixed(2) : (record.WorkCompletedCount / record.WorkCount).toFixed(2)
                return record.IsComplete ? <Tag color='success'>{percent} %</Tag> : <Tag color='warning'>{percent} %</Tag>
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'IsEnd',
            render: (value, record) => value === false ? <Tag color='blue'>Đang làm</Tag> : <Tag color='success'>Kết thúc</Tag>,
        },
        {
            title: 'Thành viên',
            dataIndex: 'Users',
            render: (value, record) => {
                return (
                    <Avatar.Group maxCount={3}>
                        {value.map((item) => (
                            <StandardAvatar size={32} src={item.AvatarUrl} key={item.UserId} />
                        ))}
                    </Avatar.Group>
                )
            },
            className: 'pt-0 pb-0',
        },
        {
            title: 'Bắt đầu',
            dataIndex: 'BeginDate',
        },
        {
            title: 'Kết thúc',
            dataIndex: 'EndDate',
        },
    ]

    return (
        <Table
            size='middle'
            rowKey='ProjectId'
            loading={isLoadingProjects}
            dataSource={projects}
            columns={columns}
            pagination={{
                ...pagination,
                showSizeChanger: true,
                size: 'default',
                showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} bản ghi`,
                onChange: (current, size) => {
                    const newPagination = {
                        ...pagination,
                        current: current,
                        pageSize: size,
                    }
                    dispatch(
                        setState({
                            key: 'pagination',
                            value: newPagination,
                        })
                    )
                    dispatch(refreshProjects())
                    localStorage.setItem('project_page_size', size)
                },
            }}
            rowSelection={{
                type: 'radio',
                onChange: (_, selectedRows) => {
                    const record = selectedRows[0]
                    dispatch(
                        setState({
                            key: 'selectedProject',
                            value: record,
                        })
                    )
                },
                selectedRowKeys: [selectedProject?.ProjectId],
            }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        dispatch(
                            setState({
                                key: 'selectedProject',
                                value: record,
                            })
                        )
                    },
                }
            }}
            expandable={{
                expandedRowRender: (record) => <TableProjectDetail data={record} />,
                expandedRowClassName: () => 'expand-row-detail',
            }}
        />
    )
}
