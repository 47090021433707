import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, PushpinOutlined, ClearOutlined, DisconnectOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Select, Space, Popconfirm } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { refreshWorks, setState } from '../../AssignmentReducer'

let timeout

const fetch = (value, callback, setIsBusy) => {
    if (timeout) {
        clearTimeout(timeout)
        timeout = null
    }

    const fake = () => {
        setIsBusy(true)
        const url = `/project/get-dropdown?keyword=${value}`
        axios
            .get(url)
            .then(({ data }) => {
                if (data.IsSuccess) {
                    const result = data.Result.map((item) => ({
                        value: item.ProjectId,
                        label: item.ProjectName,
                    }))
                    callback(result)
                }
            })
            .finally(() => setIsBusy(false))
    }

    timeout = setTimeout(fake, 300)
}

export default function Filter() {
    const { filters, selectedWorks } = useSelector((state) => state.assignment)
    const dispatch = useDispatch()

    const [localFilters, setLocalFilters] = useState(filters)

    const [data, setData] = useState([])

    const [isBusy, setIsBusy] = useState(false)

    const [isDeleteAssignment, setIsDeleteAssignment] = useState(false)

    const [isDeleting, setIsDeleting] = useState(false)

    const handleSearch = (newValue) => {
        fetch(newValue, setData, setIsBusy)
    }

    const isDisabled = filters.projectId === 0

    const isDisabledAssignment = selectedWorks.length < 1 || selectedWorks.findIndex((n) => n.User !== null) > -1

    const isDisableDeleteAssignment = selectedWorks.length < 1 || selectedWorks.findIndex((n) => n.User == null) > -1

    return (
        <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={5}>
                <Select
                    options={data}
                    allowClear
                    showSearch
                    value={localFilters.projectId === 0 ? null : localFilters.projectId}
                    placeholder='Tên dự án'
                    defaultActiveFirstOption={false}
                    showArrow
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={(newValue) => {
                        const newLocalFilters = { ...filters, projectId: newValue }
                        setLocalFilters(newLocalFilters)
                    }}
                    className='w-100'
                    onDropdownVisibleChange={(open) => {
                        if (open) {
                            if (data.length === 0) {
                                handleSearch('')
                            }
                        }
                    }}
                    notFoundContent={<span>Không tìm thấy dự án</span>}
                    loading={isBusy}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={19} xxl={19}>
                <Space>
                    <Button
                        icon={<SearchOutlined />}
                        type='primary'
                        onClick={() => {
                            const newFilters = { ...localFilters }
                            dispatch(
                                setState({
                                    key: 'filters',
                                    value: newFilters,
                                })
                            )
                        }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        icon={<PlusOutlined />}
                        type='primary'
                        onClick={() => dispatch(setState({ key: 'isShowModalAssignmentAdd', value: true }))}
                        disabled={isDisabled}
                    >
                        Thêm mới
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalAssignmentEdit',
                                    value: true,
                                })
                            )
                        }}
                        disabled={selectedWorks.length !== 1}
                    >
                        Chỉnh sửa
                    </Button>
                    <Button
                        icon={<PushpinOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalAssignmentUser',
                                    value: true,
                                })
                            )
                        }}
                        disabled={isDisabledAssignment}
                    >
                        Phân công
                    </Button>
                    <Popconfirm
                        title='Bạn có chắc chắn muốn hủy phân công công việc không?'
                        onConfirm={() => {
                            setIsDeleteAssignment(true)
                            axios
                                .delete('/work/assignment/delete', {
                                    data: { WorkIds: selectedWorks.map((item) => item.WorkId) },
                                })
                                .then(({ data }) => {
                                    if (data.IsSuccess) {
                                        message.success('Hủy phân công công việc thành công')
                                        dispatch(refreshWorks(true))
                                    }
                                })
                                .finally(() => setIsDeleteAssignment(false))
                        }}
                        okText='Đồng ý'
                        cancelText='Không'
                    >
                        <Button icon={<DisconnectOutlined />} type='dashed' danger disabled={isDisableDeleteAssignment} loading={isDeleteAssignment}>
                            Hủy phân công
                        </Button>
                    </Popconfirm>
                    <Button
                        icon={<ClearOutlined />}
                        type='primary'
                        disabled={selectedWorks.length < 1}
                        onClick={() => dispatch(setState({ key: 'selectedWorks', value: [] }))}
                    >
                        Bỏ chọn
                    </Button>
                    <Popconfirm
                        title='Bạn có chắc chắn muốn xóa công việc không?'
                        onConfirm={() => {
                            setIsDeleting(true)
                            const ids = selectedWorks.map((n) => n.WorkId)
                            axios
                                .delete('/work/delete-multi', {
                                    data: {
                                        WorkIds: ids,
                                    },
                                })
                                .then(({ data }) => {
                                    if (data.IsSuccess) {
                                        dispatch(refreshWorks(true))
                                        message.success('Xóa công việc thành công')
                                    }
                                })
                                .finally(() => setIsDeleting(false))
                        }}
                        okText='Đồng ý'
                        cancelText='Không'
                    >
                        <Button icon={<DeleteOutlined />} type='primary' danger disabled={selectedWorks.length < 1} loading={isDeleting}>
                            Xóa
                        </Button>
                    </Popconfirm>
                </Space>
            </Col>
        </Row>
    )
}
