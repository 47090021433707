import React, { useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import Config from './../../../../Config.json'
import { SaveOutlined } from '@ant-design/icons'
import axios from 'axios'

const { reqInputMsg, minMsg, maxMsg } = Config

export default function ChangePassword() {
    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={(formData) => {
                setIsBusy(true)
                axios
                    .put('/user/change-password', formData)
                    .then(({ data }) => {
                        if (data.IsSuccess) {
                            message.success('Thay đổi mật khẩu thành công')
                            form.resetFields()
                        }
                    })
                    .finally(() => setIsBusy(false))
            }}
            autoComplete='off'
        >
            <Form.Item
                label='Mật khẩu hiện tại'
                name='CurrentPassword'
                rules={[
                    {
                        required: true,
                        message: reqInputMsg,
                    },
                    {
                        min: 3,
                        message: minMsg,
                    },
                    {
                        max: 70,
                        message: maxMsg,
                    },
                ]}
                initialValue=''
            >
                <Input.Password autoFocus />
            </Form.Item>
            <Form.Item
                label='Mật khẩu mới'
                name='NewPassword'
                rules={[
                    {
                        required: true,
                        message: reqInputMsg,
                    },
                    {
                        min: 6,
                        message: minMsg,
                    },
                    {
                        max: 70,
                        message: maxMsg,
                    },
                ]}
                initialValue=''
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label='Nhập lại mật khẩu mới'
                name='NewPasswordConfirm'
                rules={[
                    {
                        required: true,
                        message: reqInputMsg,
                    },
                    {
                        min: 6,
                        message: minMsg,
                    },
                    {
                        max: 70,
                        message: maxMsg,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('NewPassword') === value) {
                                return Promise.resolve()
                            }

                            return Promise.reject(new Error('Nhập lại mật khẩu không đúng'))
                        },
                    }),
                ]}
                initialValue=''
            >
                <Input.Password />
            </Form.Item>
            <Form.Item className='mb-0'>
                <Button htmlType='submit' type='primary' className='min-w-100-px' icon={<SaveOutlined />} loading={isBusy}>
                    Lưu
                </Button>
            </Form.Item>
        </Form>
    )
}
