import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const defaultPageSize = () => {
    const pageSize = localStorage.getItem('user_page_size')
    return pageSize !== null ? parseInt(pageSize) : 10
}

const initialState = {
    isLoadingRoles: true,
    isLoadingUsers: true,

    isShowModalUserAdd: false,
    isShowModalUserEdit: false,
    isShowModalUserSetPwd: false,

    filters: {
        keyword: '',
        roleId: 0,
    },

    pagination: {
        current: 1,
        pageSize: defaultPageSize(),
        total: 0,
    },

    roles: [],
    users: [],

    selectedUser: null
}

export const UserReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsLoadingRoles: (state, action) => {
            state.isLoadingRoles = action.payload
        },
        setIsLoadingUsers: (state, action) => {
            state.isLoadingUsers = action.payload
        },
        setIsShowModalUserAdd: (state, action) => {
            state.isShowModalUserAdd = action.payload
        },
        setIsShowModalUserEdit: (state, action) => {
            state.isShowModalUserEdit = action.payload
        },
        setState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        refreshUsers: (state, action) => {
            if (action.payload === true) {
                const newPagination = { ...state.pagination, current: 1 }
                state.pagination = newPagination
                const newFilters = { ...state.filters }
                state.filters = newFilters
            } else {
                const newFilters = { ...state.filters }
                state.filters = newFilters
            }
        },
        resetState: (state, action) => {
            return { ...initialState, pagination: { ...initialState.pagination, pageSize: defaultPageSize() } }
        }
    }
})

export const {
    setIsLoadingRoles,
    setIsLoadingUsers,
    setIsShowModalUserAdd,
    setIsShowModalUserEdit,
    setState,
    setFilters,
    setPagination,
    setRoles,
    setUsers,
    setSelectedUser,
    refreshUsers,
    resetState
} = UserReducer.actions

export default UserReducer.reducer

export const fetchRoles = () => (dispatch) => {
    dispatch(setIsLoadingRoles(true))
    axios.get("/role/get-all").then(({ data }) => {
        if (data.IsSuccess) {
            dispatch(setRoles(data.Result))
        }
    }).finally(() => dispatch(setIsLoadingRoles(false)))
}

export const fetchUsers = (filters, pagination, selectedUser) => (dispatch) => {
    const { keyword, roleId } = filters
    const url = `/user/get-pagination?keyword=${keyword}&roleId=${roleId}&pageSize=${pagination.pageSize}&pageIndex=${pagination.current}`
    dispatch(setIsLoadingUsers(true))
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            const userList = data.Result.Data.map((item, index) => {
                return {
                    ...item,
                    Index: pagination.pageSize * (pagination.current - 1) + index + 1
                }
            })
            if (selectedUser !== null) {
                const index = userList.findIndex(n => n.UserId === selectedUser.UserId)
                if (index === -1) {
                    dispatch(setSelectedUser(null))
                }
                else {
                    dispatch(setSelectedUser(userList[index]))
                }
            }
            dispatch(setUsers(userList))
            const newPagination = { ...pagination, total: data.Result.TotalRow }
            dispatch(setPagination(newPagination))
        }
    }).finally(() => dispatch(setIsLoadingUsers(false)))
}
