import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const defaultPageSize = () => {
    const pageSize = localStorage.getItem('project_page_size')
    return pageSize !== null ? parseInt(pageSize) : 10
}

const initialState = {
    isLoadingProjects: true,

    isShowModalProjectAdd: false,
    isShowModalProjectEdit: false,
    isShowModalMemberAdd: false,
    isShowModalResourceAdd: false,

    projects: [],

    filters: {
        keyword: '',
    },

    pagination: {
        current: 1,
        pageSize: defaultPageSize(),
        total: 0,
    },

    selectedProject: null
}

export const ProjectReducer = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        refreshProjects: (state, action) => {
            if (action.payload === true) {
                const newPagination = { ...state.pagination, current: 1 }
                state.pagination = newPagination
                const newFilters = { ...state.filters }
                state.filters = newFilters
            } else {
                const newFilters = { ...state.filters }
                state.filters = newFilters
            }
        },
        resetState: (state, action) => {
            return { ...initialState, pagination: { ...initialState.pagination, pageSize: defaultPageSize() } }
        }
    }
})

export const {
    setState,
    refreshProjects,
    resetState
} = ProjectReducer.actions

export default ProjectReducer.reducer

export const fetchProjects = (filters, pagination, selectedProject) => (dispatch) => {
    const { keyword } = filters
    const url = `/project/get-pagination?keyword=${keyword}`
    dispatch(setState({
        key: 'isLoadingProjects',
        value: true
    }))
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            const projectList = data.Result.Data.map((item, index) => {
                return {
                    ...item,
                    Index: pagination.pageSize * (pagination.current - 1) + index + 1
                }
            })
            if (selectedProject !== null) {
                const index = projectList.findIndex(n => n.ProjectId === selectedProject.ProjectId)
                if (index === -1) {
                    dispatch(setState({
                        key: 'selectedProject',
                        value: null
                    }))
                }
                else {
                    dispatch(setState({
                        key: 'selectedProject',
                        value: projectList[index]
                    }))
                }
            }
            dispatch(setState({
                key: 'projects',
                value: projectList
            }))
        }
    }).finally(() => {
        dispatch(setState({
            key: 'isLoadingProjects',
            value: false
        }))
    })
}