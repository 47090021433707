import { Avatar, Button, Descriptions, Space, Tabs, Popconfirm, message } from 'antd'
import React from 'react'
import { StandardAvatar } from './../../../../components'
import { TableMember, TableResource } from './../../components'
import { CoffeeOutlined } from '@ant-design/icons'
import { useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { setState } from '../../ProjectReducer'

export default function TableProjectDetail(props) {
    const { projects } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const { data } = props

    const tabItems = [
        {
            label: 'Chi tiết',
            key: 1,
            children: (
                <Descriptions labelStyle={{ color: '#1890ff' }} bordered size='small' column={12}>
                    <Descriptions.Item label='Tên dự án' span={3}>
                        {data.ProjectName}
                    </Descriptions.Item>
                    <Descriptions.Item label='Ghi chú' span={3}>
                        {data.Note}
                    </Descriptions.Item>
                    <Descriptions.Item label='Công việc' span={3}>
                        {data.WorkCount}
                    </Descriptions.Item>
                    <Descriptions.Item label='Hoàn thành' span={3}>
                        {data.WorkCompletedCount}
                    </Descriptions.Item>
                    <Descriptions.Item label='Tiến độ' span={3}>
                        {0}
                    </Descriptions.Item>
                    <Descriptions.Item label='Bắt đầu' span={3}>
                        {data.BeginDate}
                    </Descriptions.Item>
                    <Descriptions.Item label='Kết thúc' span={3}>
                        {data.EndDate}
                    </Descriptions.Item>
                    <Descriptions.Item label='Ngày tạo' span={3}>
                        {data.CreatedDate}
                    </Descriptions.Item>
                    <Descriptions.Item label='Người tạo' span={3}>
                        {data.CreatedUser?.FullName}
                    </Descriptions.Item>
                    <Descriptions.Item label='Thành viên' span={3} className='pt-0 pb-0'>
                        <Avatar.Group maxCount={5}>
                            {data?.Users.map((item) => (
                                <StandardAvatar src={item.AvatarUrl} key={item.UserId} />
                            ))}
                        </Avatar.Group>
                    </Descriptions.Item>
                </Descriptions>
            ),
        },
        { label: 'Thành viên', key: 2, children: <TableMember members={data.Users} /> },
        { label: 'Tài liệu', key: 3, children: <TableResource resources={data.Resources} /> },
    ]

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Space direction='vertical' className='w-100'>
            <Tabs defaultActiveKey='1' type='card' items={tabItems}></Tabs>
            <Space className='pb-2 ps-2 pe-2'>
                <Popconfirm
                    title='Bạn có chắc chắn muốn chỉnh sửa trạng thái dự án không?'
                    onConfirm={() => {
                        setIsBusy(true)
                        axios
                            .put(`/project/edit-status/${data.ProjectId}`)
                            .then(({ data: res }) => {
                                if (res.IsSuccess) {
                                    message.success('Kết thúc dự án thành công')
                                    const newProjects = [...projects].map((item) => {
                                        return {
                                            ...item,
                                            IsEnd: item.ProjectId === data.ProjectId ? res.Result : item.IsEnd,
                                        }
                                    })
                                    dispatch(
                                        setState({
                                            key: 'projects',
                                            value: newProjects,
                                        })
                                    )
                                }
                            })
                            .finally(() => setIsBusy(false))
                    }}
                    okText='Đồng ý'
                    cancelText='Không'
                >
                    <Button type='primary' danger={!data.IsEnd} icon={<CoffeeOutlined />} loading={isBusy}>
                        {data.IsEnd ? 'Chỉnh sửa đang làm' : 'Chỉnh sửa kết thúc'}
                    </Button>
                </Popconfirm>
            </Space>
        </Space>
    )
}
