import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const AuthView = (props) => {
    const { role: globalRole } = useSelector((state) => state.global)

    const { roles, children } = props

    const renderChildren = roles.some((n) => n === globalRole) ?? children

    return renderChildren
}

AuthView.propTypes = {
    role: PropTypes.array,
}

AuthView.defaultProps = {
    role: [],
}

export default AuthView
