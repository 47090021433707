import {
    IdcardOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    OrderedListOutlined,
    PieChartOutlined,
    ProjectOutlined,
    PushpinOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { Button, Layout, Menu, Modal, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import './style.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const { Header, Content, Sider } = Layout

const getItem = (label, key, icon, title, children, type) => {
    return {
        key,
        icon,
        title,
        children,
        label,
        type,
    }
}

const initMenu = () => {
    const path = window.location.pathname.slice(1)
    const pathArr = path.split('/')
    if (pathArr.length > 1) {
        const first = pathArr[0]
        const second = pathArr[1]
        return {
            first: first,
            second: `${first}_${second}`,
        }
    } else {
        return {
            first: '',
            second: pathArr[0],
        }
    }
}

export default function LayoutPage() {
    const { fullName, role } = useSelector((state) => state.global)

    const navigate = useNavigate()

    const [collapsed, setCollapsed] = useState(false)

    const [title, setTitle] = useState('')

    const items =
        role === 'admin'
            ? [
                  getItem(<Link to='/task'>Công việc</Link>, 'task', <OrderedListOutlined />, 'Công việc'),
                  getItem(<Link to='/user'>Người dùng</Link>, 'user', <UserOutlined />, 'Người dùng'),
                  getItem(<Link to='/project'>Dự án</Link>, 'project', <ProjectOutlined />, 'Dự án'),
                  getItem(<Link to='/assignment'>Phân công</Link>, 'assignment', <PushpinOutlined />, 'Phân công'),
                  getItem(<Link to='/report'>Báo cáo</Link>, 'report', <PieChartOutlined />, 'Báo cáo'),
                  getItem(<Link to='/profile'>Cá nhân</Link>, 'profile', <IdcardOutlined />, 'Cá nhân'),
              ]
            : [
                  getItem(<Link to='/task'>Công việc</Link>, 'task', <OrderedListOutlined />, 'Công việc'),
                  getItem(<Link to='/profile'>Cá nhân</Link>, 'profile', <IdcardOutlined />, 'Cá nhân'),
              ]

    const onCollapse = () => {
        setCollapsed(!collapsed)
    }

    useEffect(() => {
        const path = window.location.pathname.slice(1)
        if (path.indexOf('/') > -1) {
            const keyArr = path.split('/')
            const first = keyArr[0]
            const second = keyArr[1]
            const item = items.filter((n) => n.key === first)[0]
            const child = item?.children?.filter((n) => n.key === `${first}_${second}`)[0]
            setTitle(child?.title)
        } else {
            const menu = items.filter((n) => n.key === path)[0]
            setTitle(menu?.title)
        }
        // eslint-disable-next-line
    }, [])

    const onLogout = () => {
        delete axios.defaults.headers.Authorization
        localStorage.removeItem('login_info')
        navigate('/', {
            options: {
                replace: !0,
            },
        })
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsed={collapsed}
                onCollapse={onCollapse}
                className='shadow-sm'
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    zIndex: 2,
                }}
            >
                <div style={{ padding: '15px 0' }}>
                    <div className='text_logo'>{collapsed ? 'TMS' : 'Task Management'}</div>
                </div>
                <Menu
                    theme='light'
                    defaultOpenKeys={[initMenu().first]}
                    defaultSelectedKeys={[initMenu().second]}
                    mode='inline'
                    style={{ borderRight: 'none' }}
                    onSelect={({ key }) => {
                        if (key.indexOf('_') > -1) {
                            const keyArr = key.split('_')
                            const first = keyArr[0]
                            const second = keyArr[1]
                            const item = items.filter((n) => n.key === first)[0]
                            const child = item?.children.filter((n) => n.key === `${first}_${second}`)[0]
                            setTitle(child?.title)
                        } else {
                            const menu = items.filter((n) => n.key === key)[0]
                            setTitle(menu?.title)
                        }
                    }}
                    items={items}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        textAlign: 'center',
                        marginBottom: 10,
                    }}
                    className='w-100'
                ></div>
            </Sider>
            <Layout className='site-layout' style={{ marginLeft: collapsed ? 80 : 200 }}>
                <Header
                    className='site-layout-background'
                    style={{
                        padding: 0,
                        position: 'fixed',
                        zIndex: 1,
                        width: collapsed ? 'calc(100vw - 80px)' : 'calc(100vw - 200px)',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: onCollapse,
                            })}
                            <span id='page-title'>{title}</span>
                        </div>
                        <div style={{ marginRight: '8px' }}>
                            <Space>
                                <div className='pe-2'>
                                    <span>{fullName}</span>
                                </div>
                                <Button
                                    type='primary'
                                    icon={<LogoutOutlined />}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Xác nhận',
                                            content: 'Bạn có chắc chắn muốn đăng xuất không?',
                                            okText: 'Đồng ý',
                                            cancelText: 'Không',
                                            onOk: () => onLogout(),
                                            centered: true,
                                        })
                                    }}
                                >
                                    Đăng xuất
                                </Button>
                            </Space>
                        </div>
                    </div>
                </Header>
                <div style={{ overflowY: 'auto', height: 'calc(100vh - 65px)', marginTop: 65 }}>
                    <Content className='main-content'>
                        <Outlet />
                    </Content>
                </div>
            </Layout>
        </Layout>
    )
}
