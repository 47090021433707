import { Table } from 'antd'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { StandardAvatar } from './../../../../components'

export default function TableReport() {
    const { isLoadingTable, table } = useSelector((state) => state.report)

    const columns = [
        {
            title: '#',
            dataIndex: 'Index',
            align: 'center',
            width: 50,
        },
        {
            title: 'Thành viên',
            dataIndex: 'FullName',
            render: (value, record) => (
                <Fragment>
                    <StandardAvatar src={record.AvatarUrl} />
                    <span className='ms-2'>{value}</span>
                </Fragment>
            ),
            className: 'pt-0 pb-0',
        },
        {
            title: 'Khởi tạo',
            dataIndex: 'WorkInitCount',
            align: 'right',
        },
        {
            title: 'Bắt đầu',
            dataIndex: 'WorkStartCount',
            align: 'right',
        },
        {
            title: 'Đang làm',
            dataIndex: 'WorkProcessingCount',
            align: 'right',
        },
        {
            title: 'Hoàn thành',
            dataIndex: 'WorkCompletedCount',
            align: 'right',
        },
    ]

    return <Table size='middle' rowKey='UserId' loading={isLoadingTable} dataSource={table} columns={columns} pagination={!1} />
}
