import { DeleteOutlined } from '@ant-design/icons'
import { message, Popconfirm, Table, Tag } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setState } from '../../ProjectReducer'
import { RowAction, StandardAvatar } from './../../../../components'

function TableMember(props) {
    const { members } = props

    const { projects } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const column = [
        {
            title: 'Họ tên',
            dataIndex: 'FullName',
            render: (value, record) => (
                <Fragment>
                    <StandardAvatar src={record.AvatarUrl} />
                    <span className='ms-2'>{value}</span>
                </Fragment>
            ),
            className: 'pt-0 pb-0'
        },
        {
            title: 'Tài khoản',
            dataIndex: 'UserName',
        },
        {
            title: 'Phân công',
            dataIndex: 'WorkCount',
            align: 'right',
            render: (value, record) => <Tag color='purple'>{value}</Tag>,
        },
        {
            title: 'Bắt đầu',
            dataIndex: 'WorkStartCount',
            align: 'right',
            render: (value, record) => <Tag color='orange'>{value}</Tag>,
        },
        {
            title: 'Đang làm',
            dataIndex: 'WorkProcessingCount',
            align: 'right',
            render: (value, record) => <Tag color='magenta'>{value}</Tag>,
        },
        {
            title: 'Hoàn thành',
            dataIndex: 'WorkCompletedCount',
            align: 'right',
            render: (value, record) => <Tag color='geekblue'>{value}</Tag>,
        },
        {
            title: '',
            align: 'right',
            render: (_, record) => (
                <Popconfirm
                    placement='topRight'
                    title='Bạn có chắc chắn muốn xóa thành viên khỏi dự án không?'
                    onConfirm={() => removeMember(record)}
                    okText='Đồng ý'
                    cancelText='Không'
                >
                    <RowAction type='danger' icon={<DeleteOutlined />} title='Xóa' />
                </Popconfirm>
            ),
        },
    ]

    const removeMember = (member) => {
        axios.delete(`/project/member/delete/${member.UserId}`).then(({ data }) => {
            if (data.IsSuccess) {
                const newProjects = [...projects].map((item) => {
                    return {
                        ...item,
                        Users: item.Users.filter((n) => n.UserId !== member.UserId),
                    }
                })
                dispatch(
                    setState({
                        key: 'projects',
                        value: newProjects,
                    })
                )
                message.success('Xóa thành viên khỏi dự án thành công')
            }
        })
    }

    return <Table size='small' rowKey='UserId' dataSource={members} columns={column} pagination={false} />
}

TableMember.propTypes = {
    members: PropTypes.array,
}

TableMember.defaultProps = {
    members: [],
}

export default TableMember
