import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    
}

export const ProfileReducer = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        resetState: (state, action) => {
            return { ...initialState }
        }
    }
})

export const {
    setState,
    resetState
} = ProfileReducer.actions

export default ProfileReducer.reducer