import { Col, DatePicker, Form, Input, message, Row } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshProjects, setState } from '../../ProjectReducer'
import { StandardModal, StandardUploadImage } from './../../../../components'
import Config from './../../../../Config.json'

const { reqInputMsg, maxMsg, dateFormat } = Config

export default function ModalProjectAdd(props) {
    const { isShowModalProjectAdd } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    return (
        <StandardModal
            isShow={isShowModalProjectAdd}
            onSave={() => form.submit()}
            onCancel={() =>
                dispatch(
                    setState({
                        key: 'isShowModalProjectAdd',
                        value: false,
                    })
                )
            }
            isBusy={isBusy}
            title='Thêm mới dự án'
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(formData) => {
                    setIsBusy(true)
                    const beginDate = formData['BeginDate'].format(dateFormat)
                    const endDate = formData['EndDate'].format(dateFormat)
                    const req = {
                        ...formData,
                        BeginDate: beginDate,
                        EndDate: endDate,
                    }
                    axios
                        .post('/project/add', req)
                        .then(({ data }) => {
                            if (data.IsSuccess) {
                                dispatch(refreshProjects(true))
                                dispatch(
                                    setState({
                                        key: 'isShowModalProjectAdd',
                                        value: false,
                                    })
                                )
                                message.success('Thêm mới dự án thành công')
                            }
                        })
                        .finally(() => setIsBusy(false))
                }}
                autoComplete='off'
            >
                <Form.Item label='' name='AvatarUrl' className='text-center' initialValue=''>
                    <StandardUploadImage
                        onCompleted={(data) => {
                            form.setFieldsValue({
                                AvatarUrl: data.Result.Url,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label='Tên dự án'
                    name='ProjectName'
                    rules={[
                        {
                            required: true,
                            message: reqInputMsg,
                        },
                        {
                            max: 100,
                            message: maxMsg,
                        },
                    ]}
                    initialValue=''
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item
                    label='Ghi chú'
                    name='Note'
                    rules={[
                        {
                            max: 2_000,
                            message: maxMsg,
                        },
                    ]}
                    initialValue=''
                >
                    <Input />
                </Form.Item>
                <Row gutter={[12, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label='Ngày bắt đầu'
                            name='BeginDate'
                            rules={[
                                {
                                    required: true,
                                    message: reqInputMsg,
                                },
                            ]}
                            initialValue={null}
                        >
                            <DatePicker className='w-100' placeholder='dd/mm/yyyy' format={dateFormat} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label='Ngày kết thúc'
                            name='EndDate'
                            rules={[
                                {
                                    required: true,
                                    message: reqInputMsg,
                                },
                            ]}
                            initialValue={null}
                        >
                            <DatePicker className='w-100' placeholder='dd/mm/yyyy' format={dateFormat} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StandardModal>
    )
}
