import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select, Space } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setState } from './../../TaskReducer'

const statusOptions = [
    { value: 1, label: 'Khởi tạo' },
    { value: 2, label: 'Bắt đầu' },
    { value: 3, label: 'Đang làm' },
    { value: 4, label: 'Hoàn thành' },
]

export default function Filter() {
    const { projects, isLoadingProjects, filters, selectedTask } = useSelector((state) => state.task)
    const dispatch = useDispatch()

    const [localFilters, setLocalFilters] = useState(filters)

    const projectOptions = projects.map((item) => {
        return {
            value: item.ProjectId,
            label: item.ProjectName + ` (${item.CountWork})`,
        }
    })

    return (
        <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={14} lg={8} xl={6} xxl={5}>
                <Select
                    loading={isLoadingProjects}
                    allowClear
                    placeholder='Chọn dự án'
                    className='w-100'
                    options={projectOptions}
                    onChange={(value) => {
                        const newLocalFilters = {
                            ...filters,
                            projectId: value ?? 0,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={10} lg={6} xl={5} xxl={3}>
                <Select
                    allowClear
                    placeholder='Chọn trạng thái'
                    className='w-100'
                    options={statusOptions}
                    onChange={(value) => {
                        const newLocalFilters = {
                            ...filters,
                            statusId: value ?? 0,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={13} xxl={16}>
                <Space>
                    <Button
                        icon={<SearchOutlined />}
                        type='primary'
                        onClick={() => {
                            const newFilters = { ...localFilters }
                            dispatch(setState({ key: 'filters', value: newFilters }))
                        }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalEditStatus',
                                    value: true,
                                })
                            )
                        }}
                        disabled={selectedTask === null}
                    >
                        Chỉnh sửa trạng thái
                    </Button>
                </Space>
            </Col>
        </Row>
    )
}
