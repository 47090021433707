import { Form, Input, message } from 'antd'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardModal } from '../../../../components'
import Config from './../../../../Config.json'
import { setState } from './../../UserReducer'

const { reqInputMsg, minMsg, maxMsg } = Config

export default function ModalSetPassword() {
    const { isShowModalUserSetPwd, selectedUser } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    const closeModal = () => dispatch(setState({ key: 'isShowModalUserSetPwd', value: false }))

    useEffect(() => {
        if (isShowModalUserSetPwd) {
            form.setFieldsValue({
                UserId: selectedUser.UserId,
            })
        }
        // eslint-disable-next-line
    }, [isShowModalUserSetPwd])

    return (
        <StandardModal
            isShow={isShowModalUserSetPwd}
            onSave={() => form.submit()}
            onCancel={closeModal}
            isBusy={isBusy}
            title='Đặt mật khẩu người dùng'
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(formData) => {
                    setIsBusy(true)
                    axios
                        .put('/user/set-password', formData)
                        .then(({ data }) => {
                            if (data.IsSuccess) {
                                closeModal()
                                message.success('Đặt mật khẩu người dùng thành công')
                            }
                        })
                        .finally(() => setIsBusy(false))
                }}
                autoComplete='off'
            >
                <Form.Item name='UserId' hidden initialValue={0}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Mật khẩu mới'
                    name='NewPassword'
                    rules={[
                        {
                            required: true,
                            message: reqInputMsg,
                        },
                        {
                            min: 6,
                            message: minMsg,
                        },
                        {
                            max: 70,
                            message: maxMsg,
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </StandardModal>
    )
}
