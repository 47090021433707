import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Config from './../../Config.json'
import dayjs from 'dayjs'

const { dateFormat } = Config

const fromDate = dayjs().date(1).format(dateFormat)

const initialState = {
    isLoadingTable: true,

    filters: {
        fromDate: fromDate,
        toDate: ''
    },

    table: []
}

export const ReportReducer = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        resetState: (state, action) => {
            return { ...initialState }
        }
    }
})

export const {
    setState,
    resetState
} = ReportReducer.actions

export default ReportReducer.reducer

export const fetchTable = (filters) => (dispatch) => {
    dispatch(setState({ key: 'isLoadingTable', value: true }))
    const { fromDate, toDate } = filters
    const url = `/report/task?FromDate=${fromDate}&ToDate=${toDate}`
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            const list = data.Result.map((item, index) => {
                return {
                    ...item,
                    Index: index + 1
                }
            })
            dispatch(setState({ key: 'table', value: list }))
        }
    }).finally(() => dispatch(setState({ key: 'isLoadingTable', value: false })))
}