import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardModal, StandardUploadImage } from './../../../../components'
import { setIsShowModalUserEdit, refreshUsers } from './../../UserReducer'
import { Input, Form, Select, Radio, Row, Col, message } from 'antd'
import axios from 'axios'
import Config from './../../../../Config.json'

const { reqInputMsg, reqSelectMsg, minMsg, maxMsg } = Config

function ModalUserEdit(props) {
    const { isShowModalUserEdit, roles, selectedUser } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    const [avatarUrl, setAvatarUrl] = useState('')

    const roleOptions = roles.map((item) => {
        return {
            value: item.RoleId,
            label: item.RoleName,
        }
    })

    useEffect(() => {
        if (isShowModalUserEdit) {
            setAvatarUrl(selectedUser.AvatarUrl)
            form.setFieldsValue(selectedUser)
        }
        // eslint-disable-next-line
    }, [isShowModalUserEdit])

    return (
        <StandardModal
            isShow={isShowModalUserEdit}
            onSave={() => form.submit()}
            onCancel={() => dispatch(setIsShowModalUserEdit(false))}
            isBusy={isBusy}
            title='Chỉnh sửa người dùng'
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(formData) => {
                    setIsBusy(true)
                    axios
                        .put('/user/edit', formData)
                        .then(({ data }) => {
                            if (data.IsSuccess) {
                                dispatch(refreshUsers())
                                dispatch(setIsShowModalUserEdit(false))
                                message.success('Chỉnh sửa người dùng thành công')
                            }
                        })
                        .finally(() => setIsBusy(false))
                }}
                autoComplete='off'
            >
                <Form.Item name='UserId' hidden initialValue={0}>
                    <Input />
                </Form.Item>
                <Form.Item label='' name='AvatarUrl' className='text-center' initialValue=''>
                    <StandardUploadImage
                        initUrl={avatarUrl}
                        onCompleted={(data) => {
                            form.setFieldsValue({
                                AvatarUrl: data.Result.Url,
                            })
                        }}
                    />
                </Form.Item>
                <Row gutter={[12, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label='Họ tên'
                            name='FullName'
                            rules={[
                                {
                                    required: true,
                                    message: reqInputMsg,
                                },
                                {
                                    min: 3,
                                    message: minMsg,
                                },
                                {
                                    max: 100,
                                    message: maxMsg,
                                },
                            ]}
                            initialValue=''
                        >
                            <Input autoFocus />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label='Tài khoản'
                            name='UserName'
                            rules={[
                                {
                                    required: true,
                                    message: reqInputMsg,
                                },
                                {
                                    min: 3,
                                    message: minMsg,
                                },
                                {
                                    max: 100,
                                    message: maxMsg,
                                },
                            ]}
                            initialValue=''
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label='Vai trò'
                            name='RoleId'
                            rules={[
                                {
                                    required: true,
                                    message: reqSelectMsg,
                                },
                            ]}
                            initialValue={null}
                        >
                            <Select allowClear className='w-100' options={roleOptions} placeholder='Chọn vai trò' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label='Trạng thái' name='StatusId' initialValue={1}>
                            <Radio.Group>
                                <Radio value={1}>Hoạt động</Radio>
                                <Radio value={2}>Tạm khóa</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StandardModal>
    )
}

export default ModalUserEdit
