import { EditOutlined, KeyOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Select, Space } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilters, setIsShowModalUserAdd, setIsShowModalUserEdit, setPagination, setState } from './../../UserReducer'

function Filter(props) {
    const { roles, filters, isLoadingRoles, selectedUser, pagination } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const [localFilters, setLocalFilters] = useState(filters)

    const roleOptions = roles.map((item) => {
        return {
            value: item.RoleId,
            label: item.RoleName,
        }
    })

    const isDisabled = selectedUser === null || selectedUser?.IsAdmin

    return (
        <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={3}>
                <Input
                    allowClear
                    placeholder='Họ tên, tài khoản'
                    onChange={(e) => {
                        const value = e.target.value
                        const newLocalFilters = {
                            ...filters,
                            keyword: value,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={3}>
                <Select
                    loading={isLoadingRoles}
                    allowClear
                    placeholder='Chọn vai trò'
                    className='w-100'
                    options={roleOptions}
                    onChange={(value) => {
                        const newLocalFilters = {
                            ...filters,
                            roleId: value ?? 0,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={18}>
                <Space>
                    <Button
                        icon={<SearchOutlined />}
                        type='primary'
                        onClick={() => {
                            const newPagination = { ...pagination, current: 1 }
                            dispatch(setPagination(newPagination))
                            const newFilters = { ...localFilters }
                            dispatch(setFilters(newFilters))
                        }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button icon={<PlusOutlined />} type='primary' onClick={() => dispatch(setIsShowModalUserAdd(true))}>
                        Thêm mới
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(setIsShowModalUserEdit(true))
                        }}
                        disabled={isDisabled}
                    >
                        Chỉnh sửa
                    </Button>
                    <Button
                        icon={<KeyOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(setState({ key: 'isShowModalUserSetPwd', value: true }))
                        }}
                        disabled={isDisabled}
                    >
                        Đặt mật khẩu
                    </Button>
                </Space>
            </Col>
        </Row>
    )
}

export default Filter
