import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Upload } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'
import axios from 'axios'

function StandardUploadFile(props) {
    const { onCompleted, fileList, multiple, onRemove } = props

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Upload
            listType='picture'
            customRequest={(options) => {
                setIsBusy(true)
                const { file } = options
                const uid = file.uid
                const fmData = new FormData()
                fmData.append('file', file)
                axios
                    .post('/file-manager/upload-file', fmData)
                    .then(({ data }) => {
                        data.Result.Id = uid
                        onCompleted(data)
                    })
                    .finally(() => setIsBusy(false))
            }}
            fileList={fileList}
            multiple={multiple}
            onRemove={onRemove}
        >
            <Button loading={isBusy} type='primary' icon={<FileAddOutlined />}>
                Chọn file
            </Button>
        </Upload>
    )
}

StandardUploadFile.propTypes = {
    onCompleted: PropTypes.func,
    fileList: PropTypes.array,
    multiple: PropTypes.bool,
    onRemove: PropTypes.func,
}

StandardUploadFile.defaultProps = {
    onCompleted: () => {},
    fileList: [],
    multiple: false,
    onRemove: () => {},
}

export default StandardUploadFile
