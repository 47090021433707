import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardAvatar } from '../../../../components'
import { refreshUsers, setPagination, setSelectedUser } from '../../UserReducer'

function TableUser(props) {
    const { isLoadingUsers, users, pagination, selectedUser } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const columns = [
        {
            title: '#',
            dataIndex: 'Index',
            width: 50,
            align: 'center',
        },
        {
            title: 'Họ tên',
            dataIndex: 'FullName',
            render: (value, record) => (
                <Fragment>
                    <StandardAvatar src={record.AvatarUrl} />
                    <span className='ms-2'>{value}</span>
                </Fragment>
            ),
            className: 'pt-0 pb-0',
        },
        {
            title: 'Tài khoản',
            dataIndex: 'UserName',
        },
        {
            title: 'Vai trò',
            dataIndex: 'RoleName',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'StatusId',
            render: (value, record) =>
                value === 1 ? (
                    <Tag color='success' icon={<UnlockOutlined />}>
                        Hoạt động
                    </Tag>
                ) : (
                    <Tag color='warning' icon={<LockOutlined />}>
                        Tạm khóa
                    </Tag>
                ),
        },
    ]

    return (
        <Table
            size='middle'
            rowKey='UserId'
            loading={isLoadingUsers}
            dataSource={users}
            columns={columns}
            pagination={{
                ...pagination,
                showSizeChanger: true,
                size: 'default',
                showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} bản ghi`,
                onChange: (current, size) => {
                    const newPagination = {
                        ...pagination,
                        current: current,
                        pageSize: size,
                    }
                    dispatch(setPagination(newPagination))
                    dispatch(refreshUsers())
                    localStorage.setItem('user_page_size', size)
                },
            }}
            rowSelection={{
                type: 'radio',
                onChange: (_, selectedRows) => {
                    const record = selectedRows[0]
                    dispatch(setSelectedUser(record))
                },
                selectedRowKeys: [selectedUser?.UserId],
            }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        dispatch(setSelectedUser(record))
                    },
                }
            }}
        />
    )
}

export default TableUser
