import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { Fragment, useState, useEffect } from 'react'

function StandardUploadImage(props) {
    const { onStart, onCompleted, initUrl } = props

    const [imageUrl, setImageUrl] = useState('')

    const [isBusy, setIsBusy] = useState(false)

    useEffect(() => {
        if (initUrl !== undefined && initUrl !== null && initUrl !== '') {
            const fullThumbnailUrl = window.apiUrl + '/file-manager/thumbnail?url=' + initUrl + '&size=200'
            setImageUrl(fullThumbnailUrl)
        }
    }, [initUrl])

    const beforeUpload = (file) => {
        const isJpgOrPngGif = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
        if (!isJpgOrPngGif) {
            message.info('Vui lòng chọn file jpeg png gif')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.info('Vui lòng chọn ảnh bé hơn 2MB')
        }
        return isJpgOrPngGif && isLt2M
    }

    const uploadButton = () => {
        return (
            <div>
                {isBusy ? (
                    <LoadingOutlined />
                ) : (
                    <Fragment>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Chọn ảnh</div>
                    </Fragment>
                )}
            </div>
        )
    }

    return (
        <Upload
            disabled={isBusy}
            listType='picture-card'
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={(options) => {
                // eslint-disable-next-line
                const { onSuccess, onError, file, onProgress } = options
                const fmData = new FormData()
                fmData.append('file', file)
                setIsBusy(true)
                setImageUrl('')
                onStart()
                axios
                    .post('/file-manager/upload-image', fmData)
                    .then(({ data }) => {
                        const fullThumbnailUrl = window.apiUrl + data.Result.ThumbnailUrl
                        setImageUrl(fullThumbnailUrl)
                        onCompleted(data)
                    })
                    .finally(() => setIsBusy(false))
            }}
        >
            {imageUrl !== '' ? <img src={imageUrl} style={{ width: '100%', height: '100%', borderRadius: 4, objectFit: 'cover' }} alt='avatar' /> : uploadButton()}
        </Upload>
    )
}

StandardUploadImage.propTypes = {
    onStart: PropTypes.func,
    onCompleted: PropTypes.func,
    initUrl: PropTypes.string,
}

StandardUploadImage.defaultProps = {
    onStart: () => {},
    onCompleted: () => {},
    initUrl: '',
}

export default StandardUploadImage
