import { ConfigProvider, message } from 'antd'
import 'antd/dist/reset.css'
import locale from 'antd/lib/locale/vi_VN'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import './App.css'
import { AssignmentPage, DashboardPage, LayoutPage, LoginPage, ProfilePage, ProjectPage, ReportPage, TaskPage, UserPage } from './pages'

axios.defaults.timeout = 10_000
axios.defaults.baseURL = window.apiUrl

function setAccessToken() {
    const localInfo = localStorage.getItem('login_info')
    if (localInfo !== null) {
        const json = JSON.parse(localInfo)
        axios.defaults.headers.Authorization = `Bearer ${json.AccessToken}`
    }
}

setAccessToken()

axios.interceptors.response.use(
    function (response) {
        try {
            const responseType = response.config.responseType
            if (responseType !== 'blob') {
                if (!response.data.IsSuccess) {
                    if (response.data.Message !== '') {
                        message.error(response.data.Message)
                    }
                }
            }
        } catch { }
        return response
    },
    function (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('login_info')
            window.location.reload()
        }
        message.error('Hệ thống đang gặp sự cố, vui lòng liên hệ quản trị viên')
        return Promise.reject(error)
    }
)

function App() {
    const { role } = useSelector(state => state.global)

    return (
        <ConfigProvider theme={{
            token: {
                borderRadius: 4
            },
        }} locale={locale}>
            <Routes>
                <Route path='/' element={<Outlet />}>
                    <Route index element={<LoginPage />} />
                    <Route element={role !== '' ? <LayoutPage /> : <Navigate to='/' replace />}>
                        <Route path='dashboard' element={<DashboardPage />} />
                        <Route path='task' element={<TaskPage />} />
                        <Route path='assignment' element={<AssignmentPage />} />
                        <Route path='project' element={<ProjectPage />} />
                        <Route path='user' element={<UserPage />} />
                        <Route path='profile' element={<ProfilePage />} />
                        <Route path='report' element={<ReportPage />} />
                    </Route>
                </Route>
            </Routes>
        </ConfigProvider>
    )
}

export default App
