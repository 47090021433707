import { Card, Space } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Filter, TableTask, ModalEditStatus } from './components'
import { fetchProjects, resetState, fetchTasks } from './TaskReducer'

const TaskPage = () => {
    const { filters, selectedProject, selectedTask } = useSelector((state) => state.task)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProjects())

        return () => {
            dispatch(resetState())
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchTasks(filters, selectedProject, selectedTask))
        // eslint-disable-next-line
    }, [filters])

    return (
        <Fragment>
            <Space direction='vertical' className='w-100'>
                <Card size='small'>
                    <Filter />
                </Card>
                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <TableTask />
                </Card>
            </Space>
            <ModalEditStatus />
        </Fragment>
    )
}

export default TaskPage
