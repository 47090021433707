import { Card, Space } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Filter, ModalUserAdd, TableUser, ModalUserEdit, ModalSetPassword } from './components'
import { fetchRoles, fetchUsers, resetState } from './UserReducer'

export default function UserPage() {
    const { filters, pagination, selectedUser } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchRoles())

        return () => {
            dispatch(resetState())
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchUsers(filters, pagination, selectedUser))
        // eslint-disable-next-line
    }, [filters])

    return (
        <Fragment>
            <Space direction='vertical' className='w-100'>
                <Card size='small'>
                    <Filter />
                </Card>
                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <TableUser />
                </Card>
            </Space>
            <ModalUserAdd />
            <ModalUserEdit />
            <ModalSetPassword />
        </Fragment>
    )
}
