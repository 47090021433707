import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    isLoadingProjects: true,
    isLoadingTasks: true,

    isShowModalEditStatus: false,

    filters: {
        projectId: 0,
        statusId: 0,
    },

    projects: [],
    tasks: [],

    selectedProject: null,
    selectedTask: null
}

export const TaskReducer = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        resetState: (state, action) => {
            return { ...initialState }
        }
    }
})

export const {
    setState,
    resetState
} = TaskReducer.actions

export default TaskReducer.reducer

export const fetchProjects = () => (dispatch) => {
    dispatch(setState({ key: 'isLoadingProjects', value: true }))
    axios.get('/member/get-project').then(({ data }) => {
        if (data.IsSuccess) {
            const projectList = data.Result.map((item, index) => {
                return {
                    ...item,
                    Index: index + 1
                }
            })
            dispatch(setState({
                key: 'projects',
                value: projectList
            }))
        }
    }).finally(() => dispatch(setState({ key: 'isLoadingProjects', value: false })))
}

export const fetchTasks = (filters, selectedProject, selectedTask) => (dispatch) => {
    const { projectId, statusId } = filters
    const url = `/work/member/get-by-project?projectId=${projectId}&statusId=${statusId}`
    dispatch(setState({
        key: 'isLoadingTasks',
        value: true
    }))
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            const taskList = data.Result.map((item, index) => {
                return {
                    ...item,
                    Index: index + 1
                }
            })
            if (selectedTask !== null) {
                const index = taskList.findIndex(n => n.WorkId === selectedTask.WorkId)
                if (index === -1) {
                    dispatch(setState({
                        key: 'selectedTask',
                        value: null
                    }))
                }
                else {
                    dispatch(setState({
                        key: 'selectedTask',
                        value: taskList[index]
                    }))
                }
            }
            dispatch(setState({
                key: 'tasks',
                value: taskList
            }))
        }
    }).finally(() => {
        dispatch(setState({
            key: 'isLoadingTasks',
            value: false
        }))
    })
}