import { Form, message, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshWorks, setState } from '../../AssignmentReducer'
import { StandardModal } from './../../../../components'
import Config from './../../../../Config.json'

const { reqSelectMsg } = Config

export default function ModalAssignmentUser() {
    const { isShowModalAssignmentUser: isShowModal, users, selectedWorks } = useSelector((state) => state.assignment)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    const closeModal = () =>
        dispatch(
            setState({
                key: 'isShowModalAssignmentUser',
                value: false,
            })
        )

    const userOptions = users.map((item) => {
        return {
            value: item.UserId,
            label: item.FullName,
        }
    })

    return (
        <StandardModal
            isShow={isShowModal}
            onSave={() => form.submit()}
            onCancel={closeModal}
            isBusy={isBusy}
            title='phân công công việc'
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(formData) => {
                    setIsBusy(true)
                    const req = {
                        UserId: formData.UserId,
                        WorkIds: selectedWorks.map((n) => n.WorkId),
                    }
                    axios
                        .put('/work/assignment', req)
                        .then(({ data }) => {
                            if (data.IsSuccess) {
                                message.success('Phân công công việc thành công')
                                dispatch(refreshWorks())
                                closeModal()
                            }
                        })
                        .finally(() => setIsBusy(false))
                }}
                autoComplete='off'
            >
                <Form.Item
                    label='Thành viên'
                    name='UserId'
                    rules={[
                        {
                            required: true,
                            message: reqSelectMsg,
                        },
                    ]}
                    initialValue={null}
                >
                    <Select
                        allowClear
                        className='w-100'
                        options={userOptions}
                        placeholder='Chọn thành viên'
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </Form.Item>
            </Form>
        </StandardModal>
    )
}
