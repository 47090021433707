import { CameraOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { getFullThumbnailUrl } from './../../utils/stringUtil'

function StandardAvatar(props) {
    const { src, size, icon } = props

    return <Avatar size={size} src={getFullThumbnailUrl(src)} icon={icon} />
}

StandardAvatar.propTypes = {
    src: PropTypes.string,
    size: PropTypes.number,
}

StandardAvatar.defaultProps = {
    src: '',
    size: 32,
    icon: <CameraOutlined />,
}

export default StandardAvatar
