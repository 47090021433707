import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

function FormatNumber(props) {
    const { children, maxDigit, before, after } = props

    return (
        <Fragment>{before}{children.toLocaleString('en-US', { maximumFractionDigits: maxDigit })}{after}</Fragment>
    )
}

FormatNumber.propTypes = {
    children: PropTypes.number,
    maxDigit: PropTypes.number,
    before: PropTypes.string,
    after: PropTypes.string
}

FormatNumber.defaultProps = {
    children: 0,
    maxDigit: 0,
    before: '',
    after: ''
}

export default FormatNumber
