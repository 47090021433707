import axios from "axios"

const downloadFile = (data, fileName) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

const exportExcel = (url, fileName, func) => {
    axios.get(url, {
        responseType: 'blob',
    }).then(({ data }) => {
        downloadFile(data, fileName ?? 'ExportName.xlsx')
    }).finally(() => {
        func()
    })
}

export {
    exportExcel
}