import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Row, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { exportExcel } from '../../../../utils/apiUtil'
import { setState } from '../../ReportReducer'
import Config from './../../../../Config.json'

const { dateFormat } = Config

const fromDate = dayjs().date(1)

export default function Filter() {
    const { filters } = useSelector((state) => state.report)
    const dispatch = useDispatch()

    const [localFilters, setLocalFilters] = useState(filters)

    const [isExporting, setIsExporting] = useState(false)

    return (
        <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={3}>
                <DatePicker
                    format={dateFormat}
                    placeholder='Từ ngày'
                    className='w-100'
                    defaultValue={fromDate}
                    onChange={(date, dateString) => {
                        const newLocalFilters = {
                            ...filters,
                            fromDate: dateString,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={3}>
                <DatePicker
                    format={dateFormat}
                    placeholder='Đến ngày'
                    className='w-100'
                    onChange={(date, dateString) => {
                        const newLocalFilters = {
                            ...filters,
                            toDate: dateString,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={18}>
                <Space>
                    <Button
                        icon={<SearchOutlined />}
                        type='primary'
                        onClick={() => {
                            const newFilters = { ...localFilters }
                            dispatch(
                                setState({
                                    key: 'filters',
                                    value: newFilters,
                                })
                            )
                        }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        loading={isExporting}
                        icon={<FileExcelOutlined />}
                        type='primary'
                        onClick={() => {
                            setIsExporting(true)
                            const { fromDate, toDate } = localFilters
                            const url = `/report/export?FromDate=${fromDate}&ToDate=${toDate}`
                            exportExcel(url, 'Export.xlsx', () => setIsExporting(false))
                        }}
                    >
                        Xuất excel
                    </Button>
                </Space>
            </Col>
        </Row>
    )
}
