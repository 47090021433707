import { DeleteOutlined } from '@ant-design/icons'
import { message, Space, Table } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DebounceSelect, RowAction, StandardModal } from '../../../../components'
import { setState } from '../../ProjectReducer'

export default function ModalMemberAdd() {
    const { isShowModalMemberAdd, selectedProject, projects } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const [isBusy, setIsBusy] = useState(false)

    const [members, setMembers] = useState([])

    async function searchMember(keyword) {
        const url = `/project/member/filter?projectId=${selectedProject?.ProjectId}&keyword=${keyword ?? ''}`
        return axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                return data.Result.map((item) => ({
                    label: item.FullName,
                    value: item.UserId,
                }))
            }
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'Index',
            width: 50,
            align: 'center',
        },
        {
            title: 'Họ tên',
            dataIndex: 'FullName',
        },
        {
            title: '',
            align: 'right',
            render: (_, record) => <RowAction type='danger' icon={<DeleteOutlined />} title='Xóa' onClick={() => removeMember(record)} />,
        },
    ]

    const removeMember = (member) => {
        const newMembers = [...members]
            .filter((n) => n.UserId !== member.UserId)
            .map((item, index) => {
                return {
                    ...item,
                    Index: index + 1,
                }
            })
        setMembers(newMembers)
    }

    const closeModal = () =>
        dispatch(
            setState({
                key: 'isShowModalMemberAdd',
                value: false,
            })
        )

    return (
        <StandardModal
            isShow={isShowModalMemberAdd}
            onSave={() => {
                if (members.length === 0) {
                    message.info('Vui lòng chọn thành viên trước khi lưu')
                    return
                }
                setIsBusy(true)
                const req = {
                    ProjectId: selectedProject?.ProjectId,
                    UserIds: members.map((item) => item.UserId),
                }
                axios
                    .post('/project/member/add', req)
                    .then(async ({ data }) => {
                        if (data.IsSuccess) {
                            const response = await axios.get(`/member/get-by-project/${selectedProject?.ProjectId}`)
                            const { data: dataResource } = response
                            if (dataResource.IsSuccess) {
                                const newProjects = [...projects].map((item) => {
                                    return {
                                        ...item,
                                        Users: dataResource.Result,
                                    }
                                })
                                dispatch(
                                    setState({
                                        key: 'projects',
                                        value: newProjects,
                                    })
                                )
                            }
                            closeModal()
                            message.success('Thêm thành viên thành công')
                        }
                    })
                    .finally(() => setIsBusy(false))
            }}
            onCancel={closeModal}
            isBusy={isBusy}
            title='Thêm thành viên'
            afterClose={() => setMembers([])}
        >
            <Space direction='vertical' className='w-100'>
                <DebounceSelect
                    autoFocus
                    mode='multiple'
                    value={[]}
                    placeholder='Họ tên, tài khoản'
                    fetchOptions={searchMember}
                    onChange={(newValue) => {
                        const member = newValue[0]
                        if (members.findIndex((n) => n.UserId === member.value) > -1) {
                            message.info(`Thành viên '${member.FullName}' đã có trong danh sách`)
                            return
                        }
                        const newMembers = [...members]
                        newMembers.push({
                            UserId: member.value,
                            FullName: member.label,
                            Index: members.length + 1,
                        })
                        setMembers(newMembers)
                    }}
                    style={{
                        width: '100%',
                    }}
                />
                <Table size='middle' rowKey='UserId' dataSource={members} columns={columns} pagination={false} />
            </Space>
        </StandardModal>
    )
}
