import React, { Fragment, useEffect } from 'react'
import { Filter, TableProject, ModalProjectAdd, ModalProjectEdit, ModalMemberAdd, ModalResourceAdd } from './components'
import { Space, Card } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProjects, resetState } from './ProjectReducer'

export default function ProjectPage() {
    const { filters, pagination } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => dispatch(resetState())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchProjects(filters, pagination, null))
        // eslint-disable-next-line
    }, [filters])

    return (
        <Fragment>
            <Space direction='vertical' className='w-100'>
                <Card size='small'>
                    <Filter />
                </Card>
                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <TableProject />
                </Card>
            </Space>
            <ModalProjectAdd />
            <ModalProjectEdit />
            <ModalMemberAdd />
            <ModalResourceAdd />
        </Fragment>
    )
}
