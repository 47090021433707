import { Form, Input, message, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardModal } from '../../../../components'
import { refreshWorks, setState } from '../../AssignmentReducer'
import Config from './../../../../Config.json'

const { reqInputMsg, maxMsg } = Config

export default function ModalAssignmentEdit() {
    const { isShowModalAssignmentEdit: isShowModal, users, selectedWorks } = useSelector((state) => state.assignment)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    const closeModal = () =>
        dispatch(
            setState({
                key: 'isShowModalAssignmentEdit',
                value: false,
            })
        )

    const userOptions = users.map((item) => {
        return {
            value: item.UserId,
            label: item.FullName,
        }
    })

    useEffect(() => {
        if (isShowModal) {
            const selectedWork = selectedWorks[0]
            const value = {
                WorkId: selectedWork?.WorkId,
                WorkName: selectedWork?.WorkName,
                Note: selectedWork?.Note,
                UserId: selectedWork?.User?.UserId,
            }
            form.setFieldsValue(value)
        }
        // eslint-disable-next-line
    }, [isShowModal])

    return (
        <StandardModal
            isShow={isShowModal}
            onSave={() => {
                form.submit()
            }}
            onCancel={closeModal}
            isBusy={isBusy}
            title='Chỉnh sửa công việc'
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(formData) => {
                    setIsBusy(true)
                    axios
                        .put('/work/edit', formData)
                        .then(({ data }) => {
                            if (data.IsSuccess) {
                                dispatch(refreshWorks())
                                closeModal()
                                message.success('Chỉnh công việc thành công')
                            }
                        })
                        .finally(() => {
                            setIsBusy(false)
                        })
                }}
                autoComplete='off'
            >
                <Form.Item name='WorkId' hidden initialValue={0}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Tên công việc'
                    name='WorkName'
                    rules={[
                        {
                            required: true,
                            message: reqInputMsg,
                        },
                        {
                            max: 100,
                            message: maxMsg,
                        },
                    ]}
                    initialValue=''
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item
                    label='Ghi chú'
                    name='Note'
                    rules={[
                        {
                            max: 2000,
                            message: maxMsg,
                        },
                    ]}
                    initialValue=''
                >
                    <Input />
                </Form.Item>
                <Form.Item label='Thành viên' name='UserId' initialValue={null}>
                    {/* <Select
                        allowClear
                        className='w-100'
                        options={userOptions}
                        placeholder='Chọn thành viên'
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    /> */}
                    <Select allowClear className='w-100' options={userOptions} placeholder='Chọn thành viên' />
                </Form.Item>
            </Form>
        </StandardModal>
    )
}
