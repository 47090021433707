import { configureStore } from '@reduxjs/toolkit'

import GlocalReducer from './../reducers/GlobalRecuder'
import UserReducer from './../pages/user_page/UserReducer'
import ProjectReducer from './../pages/project_page/ProjectReducer'
import AssignmentReducer from './../pages/assignment_page/AssignmentReducer'
import TaskReducer from './../pages/task_page/TaskReducer'
import ProfileReducer from './../pages/profile_page/ProfileReducer'
import ReportReducer from '../pages/report_page/ReportReducer'

export const store = configureStore({
    reducer: {
        global: GlocalReducer,
        user: UserReducer,
        project: ProjectReducer,
        assignment: AssignmentReducer,
        task: TaskReducer,
        profile: ProfileReducer,
        report: ReportReducer,
    },
})