import React from 'react'
import { ChangePassword } from './components'
import { Row, Col, Card } from 'antd'

export default function ProfilePage() {
    return (
        <Row gutter={[12, 12]}>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16, offset: 4 }}
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 8, offset: 8 }}
                xxl={{ span: 6, offset: 9 }}
            >
                <Card size='small' title='Thay đổi mật khẩu'>
                    <ChangePassword />
                </Card>
            </Col>
        </Row>
    )
}
