import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const defaultPageSize = () => {
    const pageSize = localStorage.getItem('assignment_page_size')
    return pageSize !== null ? parseInt(pageSize) : 10
}

const initialState = {
    isLoadingWorks: false,

    isShowModalAssignmentAdd: false,
    isShowModalAssignmentEdit: false,
    isShowModalAssignmentUser: false,

    users: [],
    tasks: [],

    filters: {
        projectId: 0
    },

    pagination: {
        current: 1,
        pageSize: defaultPageSize(),
        total: 0,
    },

    selectedWorks: []
}

export const AssignmentReducer = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        refreshWorks: (state, action) => {
            if (action.payload === true) {
                const newPagination = { ...state.pagination, current: 1 }
                state.pagination = newPagination
                const newFilters = { ...state.filters }
                state.filters = newFilters
            } else {
                const newFilters = { ...state.filters }
                state.filters = newFilters
            }
        },
        resetState: (state, action) => {
            return { ...initialState, pagination: { ...initialState.pagination, pageSize: defaultPageSize() } }
        }
    }
})

export const {
    setState,
    refreshWorks,
    resetState
} = AssignmentReducer.actions

export default AssignmentReducer.reducer

export const fetchWorks = (filters, pagination, selectedWorks) => (dispatch) => {
    const { projectId } = filters
    if (projectId === 0) {
        return
    }
    dispatch(setState({
        key: 'isLoadingWorks',
        value: true
    }))
    const url = `/work/get-by-project?projectId=${projectId}`
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            const workList = data.Result.map((item, index) => {
                return {
                    ...item,
                    Index: index + 1
                }
            })
            if (selectedWorks.length !== 0) {
                const selectedWorkIds = selectedWorks.map(item => item.WorkId)
                const selectedList = workList.filter(n => selectedWorkIds.includes(n.WorkId))
                dispatch(setState({
                    key: 'selectedWorks',
                    value: selectedList
                }))
            }
            dispatch(setState({
                key: 'works',
                value: workList
            }))
        }
    }).finally(() => dispatch(setState({
        key: 'isLoadingWorks',
        value: false
    })))

    axios.get(`/member/get-by-project/${projectId}`).then(({ data }) => {
        if (data.IsSuccess) {
            dispatch(setState({
                key: 'users',
                value: data.Result
            }))
        }
    })
}