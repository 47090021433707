import { Card, Space } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Filter, TableReport } from './components'
import { resetState, fetchTable } from './ReportReducer'

export default function ReportPage() {
    const { filters } = useSelector((state) => state.report)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => dispatch(resetState())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchTable(filters))
        // eslint-disable-next-line
    }, [filters])

    return (
        <Space direction='vertical' className='w-100'>
            <Card size='small'>
                <Filter />
            </Card>
            <Card size='small' bodyStyle={{ padding: 0 }}>
                <TableReport />
            </Card>
        </Space>
    )
}
