import React from 'react'
import { Button, Modal } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const StandardModal = (props) => {
    const { isShow, onSave, onCancel, afterClose, isBusy, children, title } = props

    return (
        <Modal
            title={title}
            open={isShow}
            onCancel={onCancel}
            footer={[
                <Button
                    type='default'
                    key='back'
                    onClick={onCancel}
                    icon={<CloseOutlined />}
                    tabIndex={-1}
                >
                    Đóng
                </Button>,
                <Button
                    type='primary'
                    key='submit'
                    onClick={onSave}
                    icon={<SaveOutlined />}
                    loading={isBusy}
                    // htmlType='submit'
                >
                    Lưu
                </Button>,
            ]}
            destroyOnClose={true}
            afterClose={afterClose}
            maskClosable={false}
        >
            {children}
        </Modal>
    )
}

StandardModal.propTypes = {
    isShow: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    afterClose: PropTypes.func,
    isBusy: PropTypes.bool,
    title: PropTypes.string,
}

StandardModal.defaultProps = {
    isShow: false,
    onSave: () => {},
    onCancel: () => {},
    afterClose: () => {},
    isBusy: false,
    title: 'Modal title',
}

export default StandardModal
