import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardModal } from './../../../../components'
import { setState } from './../../TaskReducer'
import { message, Radio, Space } from 'antd'
import { useEffect } from 'react'
import axios from 'axios'

export default function ModalEditStatus() {
    const { isShowModalEditStatus: isShowModal, selectedTask, tasks } = useSelector((state) => state.task)
    const dispatch = useDispatch()

    const [isBusy, setIsBusy] = useState(false)

    const [value, setValue] = useState(null)

    const closeModal = () =>
        dispatch(
            setState({
                key: 'isShowModalEditStatus',
                value: false,
            })
        )

    useEffect(() => {
        if (isShowModal) {
            setValue(selectedTask?.StatusId)
        }
        // eslint-disable-next-line
    }, [isShowModal])

    return (
        <StandardModal
            isShow={isShowModal}
            onSave={() => {
                setIsBusy(true)
                axios
                    .put('/work/member/edit-status', {
                        WorkId: selectedTask?.WorkId,
                        StatusId: value,
                    })
                    .then(({ data }) => {
                        if (data.IsSuccess) {
                            message.success('Chỉnh sửa trạng thái công việc thành công')
                            closeModal()
                            const newTasks = [...tasks].map((item) => {
                                return {
                                    ...item,
                                    StatusId: item.WorkId === selectedTask?.WorkId ? value : item.StatusId,
                                }
                            })
                            dispatch(
                                setState({
                                    key: 'tasks',
                                    value: newTasks,
                                })
                            )
                        }
                    })
                    .finally(() => setIsBusy(false))
            }}
            onCancel={closeModal}
            isBusy={isBusy}
            title='Chỉnh sửa trạng thái'
            afterClose={() => {}}
        >
            <Radio.Group
                onChange={(e) => {
                    const val = e.target.value
                    setValue(val)
                }}
                value={value}
            >
                <Space direction='vertical'>
                    <Radio value={1}>Khởi tạo</Radio>
                    <Radio value={2}>Bắt đầu</Radio>
                    <Radio value={3}>Đang làm</Radio>
                    <Radio value={4}>Hoàn thành</Radio>
                </Space>
            </Radio.Group>
        </StandardModal>
    )
}
