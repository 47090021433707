import { DeleteOutlined, EditOutlined, FileAddOutlined, PlusOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button, Col, Input, message, Popconfirm, Row, Space } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshProjects, setState } from './../../ProjectReducer'

export default function Filter() {
    const { filters, selectedProject, pagination } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const [localFilters, setLocalFilters] = useState(filters)

    const [isDeleting, setIsDeleting] = useState(false)

    const isDisabled = selectedProject === null

    return (
        <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={3}>
                <Input
                    allowClear
                    placeholder='Tên dự án, người tam gia'
                    onChange={(e) => {
                        const value = e.target.value
                        const newLocalFilters = {
                            ...filters,
                            keyword: value,
                        }
                        setLocalFilters(newLocalFilters)
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={19} xxl={21}>
                <Space>
                    <Button
                        icon={<SearchOutlined />}
                        type='primary'
                        onClick={() => {
                            const newPagination = { ...pagination, current: 1 }
                            dispatch(
                                setState({
                                    key: 'pagination',
                                    value: newPagination,
                                })
                            )
                            const newFilters = { ...localFilters }
                            dispatch(
                                setState({
                                    key: 'filters',
                                    value: newFilters,
                                })
                            )
                            // dispatch(refreshProjects(true))
                        }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        icon={<PlusOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalProjectAdd',
                                    value: true,
                                })
                            )
                        }}
                    >
                        Thêm mới
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalProjectEdit',
                                    value: true,
                                })
                            )
                        }}
                        disabled={isDisabled}
                    >
                        Chỉnh sửa
                    </Button>
                    <Button
                        icon={<UserAddOutlined />}
                        type='primary'
                        disabled={isDisabled}
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalMemberAdd',
                                    value: true,
                                })
                            )
                        }}
                    >
                        Thêm thành viên
                    </Button>
                    <Button
                        icon={<FileAddOutlined />}
                        type='primary'
                        disabled={isDisabled}
                        onClick={() => {
                            dispatch(
                                setState({
                                    key: 'isShowModalResourceAdd',
                                    value: true,
                                })
                            )
                        }}
                    >
                        Thêm tài liệu
                    </Button>
                    <Popconfirm
                        title='Bạn có chắc chắn muốn xóa dự án không?'
                        onConfirm={() => {
                            setIsDeleting(true)
                            const url = `/project/delete/${selectedProject.ProjectId}`
                            axios
                                .delete(url)
                                .then(({ data }) => {
                                    if (data.IsSuccess) {
                                        dispatch(refreshProjects(true))
                                        message.success('Xóa dự án thành công')
                                    }
                                })
                                .finally(() => setIsDeleting(false))
                        }}
                        okText='Đồng ý'
                        cancelText='Không'
                    >
                        <Button icon={<DeleteOutlined />} type='primary' danger disabled={isDisabled} loading={isDeleting}>
                            Xóa
                        </Button>
                    </Popconfirm>
                </Space>
            </Col>
        </Row>
    )
}
