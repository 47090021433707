import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setState } from '../../AssignmentReducer'
import Config from './../../../../Config.json'
import { refreshWorks } from './../../AssignmentReducer'

const { reqInputMsg, maxMsg } = Config

export default function ModalAssignmentAdd() {
    const { isShowModalAssignmentAdd: isShowModal, users, filters } = useSelector((state) => state.assignment)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [isBusy, setIsBusy] = useState(false)

    const closeModal = () =>
        dispatch(
            setState({
                key: 'isShowModalAssignmentAdd',
                value: false,
            })
        )

    const userOptions = users.map((item) => {
        return {
            value: item.UserId,
            label: item.FullName,
        }
    })

    const [isCloseModal, setIsCloseModal] = useState(true)

    return (
        <Modal
            title='Thêm mới công việc'
            open={isShowModal}
            onCancel={closeModal}
            footer={[
                <Button type='default' key='back' onClick={closeModal} icon={<CloseOutlined />} tabIndex={-1}>
                    Đóng
                </Button>,
                <Button
                    type='primary'
                    key='submit'
                    onClick={() => {
                        setIsCloseModal(true)
                        setTimeout(() => {
                            form.submit()
                        }, 0)
                    }}
                    icon={<SaveOutlined />}
                    loading={isBusy}
                >
                    Lưu
                </Button>,
                <Button
                    type='primary'
                    key='submit_add'
                    onClick={() => {
                        setIsCloseModal(false)
                        setTimeout(() => {
                            form.submit()
                        }, 0)
                    }}
                    icon={<SaveOutlined />}
                    loading={isBusy}
                >
                    Lưu & thêm mới
                </Button>,
            ]}
            destroyOnClose={true}
            afterClose={() => form.resetFields()}
            maskClosable={false}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(formData) => {
                    setIsBusy(true)
                    const req = { ...formData, ProjectId: filters.projectId }
                    axios
                        .post('/work/add', req)
                        .then(({ data }) => {
                            if (data.IsSuccess) {
                                if (isCloseModal) {
                                    closeModal()
                                } else {
                                    form.resetFields()
                                }
                                dispatch(refreshWorks())
                                message.success('Thêm mới công việc thành công')
                            }
                        })
                        .finally(() => {
                            setIsCloseModal(true)
                            setIsBusy(false)
                        })
                }}
                autoComplete='off'
            >
                <Form.Item
                    label='Tên công việc'
                    name='WorkName'
                    rules={[
                        {
                            required: true,
                            message: reqInputMsg,
                        },
                        {
                            max: 100,
                            message: maxMsg,
                        },
                    ]}
                    initialValue=''
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item
                    label='Ghi chú'
                    name='Note'
                    rules={[
                        {
                            max: 2000,
                            message: maxMsg,
                        },
                    ]}
                    initialValue=''
                >
                    <Input />
                </Form.Item>
                <Form.Item label='Thành viên' name='UserId' initialValue={null}>
                    <Select
                        allowClear
                        className='w-100'
                        options={userOptions}
                        placeholder='Chọn thành viên'
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
