import { BlockOutlined, CheckCircleOutlined, HourglassOutlined, SyncOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setState } from './../../TaskReducer'

const TableTask = () => {
    const { tasks, isLoadingTasks,selectedTask } = useSelector((state) => state.task)
    const dispatch = useDispatch()

    const columns = [
        {
            title: '#',
            dataIndex: 'Index',
            align: 'center',
            width: 50,
        },
        {
            title: 'Công việc',
            dataIndex: 'WorkName',
        },
        {
            title: 'Dự án',
            dataIndex: 'ProjectName',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'StatusId',
            render: (value, record) => renderStatus(value),
        },
    ]

    const renderStatus = (statusId) => {
        if (statusId === 1) {
            return (
                <Tag color='default' icon={<BlockOutlined />}>
                    Khởi tạo
                </Tag>
            )
        } else if (statusId === 2) {
            return (
                <Tag color='processing' icon={<HourglassOutlined />}>
                    Bắt đầu
                </Tag>
            )
        } else if (statusId === 3) {
            return (
                <Tag color='warning' icon={<SyncOutlined />}>
                    Đang làm
                </Tag>
            )
        }
        return (
            <Tag color='success' icon={<CheckCircleOutlined />}>
                Hoàn thành
            </Tag>
        )
    }

    return (
        <Table
            size='middle'
            rowKey='WorkId'
            loading={isLoadingTasks}
            dataSource={tasks}
            columns={columns}
            pagination={!1}
            rowSelection={{
                type: 'radio',
                onChange: (_, selectedRows) => {
                    const record = selectedRows[0]
                    dispatch(
                        setState({
                            key: 'selectedTask',
                            value: record,
                        })
                    )
                },
                selectedRowKeys: [selectedTask?.WorkId],
            }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        dispatch(
                            setState({
                                key: 'selectedTask',
                                value: record,
                            })
                        )
                    },
                }
            }}
        />
    )
}

export default TableTask
