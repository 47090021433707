import { Empty, message } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StandardModal, StandardUploadFile } from './../../../../components'
import { setState } from './../../ProjectReducer'

export default function ModalResourceAdd() {
    const { isShowModalResourceAdd, selectedProject, projects } = useSelector((state) => state.project)
    const dispatch = useDispatch()

    const [isBusy, setIsBusy] = useState(false)

    const [fileList, setFileList] = useState([])

    const closeModal = () =>
        dispatch(
            setState({
                key: 'isShowModalResourceAdd',
                value: false,
            })
        )

    return (
        <StandardModal
            isShow={isShowModalResourceAdd}
            onSave={() => {
                if (fileList.length === 0) {
                    message.info('Vui lòng chọn file trước khi lưu')
                    return
                }
                setIsBusy(true)
                axios
                    .post('/project/resource/add', {
                        ProjectId: selectedProject?.ProjectId,
                        Resources: fileList.map((item) => {
                            return {
                                Url: item.url,
                                FileName: item.name,
                                FileSize: item.size,
                            }
                        }),
                    })
                    .then(async ({ data }) => {
                        if (data.IsSuccess) {
                            const response = await axios.get(`/resource/get-by-project/${selectedProject?.ProjectId}`)
                            const { data: dataResource } = response
                            if (dataResource.IsSuccess) {
                                const newProjects = [...projects].map((item) => {
                                    return {
                                        ...item,
                                        Resources: dataResource.Result,
                                    }
                                })
                                dispatch(
                                    setState({
                                        key: 'projects',
                                        value: newProjects,
                                    })
                                )
                            }
                            closeModal()
                            message.success('Thêm tài liệu thành công')
                        }
                    })
                    .finally(() => setIsBusy(false))
            }}
            onCancel={closeModal}
            isBusy={isBusy}
            title='Thêm tài liệu'
            afterClose={() => setFileList([])}
        >
            <StandardUploadFile
                fileList={fileList}
                multiple
                onCompleted={(data) => {
                    if (data.IsSuccess) {
                        setFileList((prev) => {
                            const newFileList = [...prev]
                            newFileList.push({
                                uid: data.Result.Id,
                                name: data.Result.FileName,
                                status: 'done',
                                url: data.Result.Url,
                                size: data.Result.FileSize,
                            })
                            return newFileList
                        })
                    }
                }}
                onRemove={(file) => {
                    setFileList((prev) => {
                        const newFileList = prev.filter((n) => n.uid !== file.uid)
                        return newFileList
                    })
                }}
            />
            {fileList.length === 0 ? <Empty /> : null}
        </StandardModal>
    )
}
